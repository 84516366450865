// -----------------------------------------------------------------------------
// Étends le composant Buttons
// -----------------------------------------------------------------------------

.btn {
  margin-bottom: $spacer;
}

.btn-primary {
  color: $white;
  &:hover {
    background-color: scale-color($primary, $lightness: 8%);
    border-color: $primary;
  }
}

.btn .fa {
  margin-right: $spacer / 2;
}

.btn-big {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  font-weight: 700;
  text-transform: uppercase;
  .fa {
    margin: $spacer 0;
    font-size: 40px;
  }
}
