// -----------------------------------------------------------------------------
// Styles typographiques de base du site
// -----------------------------------------------------------------------------

::selection {
  color: $white;
  background: $primary;
  .text-white & {
    color: $primary;
    background: $white;
  }
}

h1,
.h1 {
  color: $white;
}

h2,
.h2 {
  width: max-content;
  padding: 0.325rem 0.5rem;
  margin: 1em auto 0.75em;
}

// listes
ul {
  padding-left: 0;
  .fa {
    min-width: 16px;
  }
}

.lead {
  padding: $spacer * 2;
  margin: $spacer * 2 0;
  color: $gray-700;
  border: 1px solid $gray-200;
  border-top: 2px solid $yellow;
}
