// -----------------------------------------------------------------------------
// Pied de page du site
// -----------------------------------------------------------------------------

.footer {
  padding: $spacer 0;
  margin-top: 5rem;
  a {
    color: $white;
    text-decoration: none;

    &:hover {
      color: darken($white, 15);
    }
  }
  ul {
    list-style-type: none;
  }

  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    font-size: small;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    .list-inline-item:not(:last-child)::after {
      content: '|';
    }
    a {
      padding: $spacer;
    }
  }

  .content-start {
    justify-content: start;
  }
  svg {
    fill: $white;
  }
}
