// -----------------------------------------------------------------------------
// Font faces declarations
// -----------------------------------------------------------------------------

@use '@fontsource/raleway/scss/mixins' as Raleway;

@each $font-weight in $font-weights {
  @include Raleway.fontFace(
    $fontName: $font-family-sans-serif,
    $weight: $font-weight
  );
  @include Raleway.fontFace(
    $fontName: $font-family-sans-serif,
    $weight: $font-weight,
    $style: italic
  );
}

// Patua
@font-face {
  font-family: 'Patua One';
  font-style: normal;
  font-weight: $font-weight-normal;
  src: local('Patua One'), local('PatuaOne-Regular'),
    url('../fonts/patua-one-latin-400.woff2') format('woff2'),
    url('../fonts/patua-one-latin-400.woff') format('woff');
  font-display: swap;
}
