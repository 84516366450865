// -----------------------------------------------------------------------------
// Étends les styles du carrousel
// -----------------------------------------------------------------------------

.carousel-indicators {
  [data-bs-target] {
    border-radius: 100%;
  }
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.carousel-item {
  &-wrapper {
    height: 100%;
  }
  &-text {
    position: absolute;
    top: 50%;
    width: 100%;
    padding: $spacer;
    background: $white;
    border-radius: 1em;
    box-shadow: 0 10px 1rem -10px $black;
    transform: translateY(-50%);
    @include media-breakpoint-up(lg) {
      right: 10%;
      width: auto;
    }
  }
  &-title {
    text-align: center;
    text-transform: uppercase;
  }
}
