// -----------------------------------------------------------------------------
// Étends le composant Card
// -----------------------------------------------------------------------------

// animation sur les cards

$scale-factor: 1.1;
$scale-factor-inv: (1 - $scale-factor) + 1;

// Animation
.card-animated {
  &,
  img,
  .card-title {
    transition-duration: 500ms;
  }
  transition-property: transform;
  img {
    transition-property: filter, opacity;
  }
  &:hover {
    transform: rotate(-6deg);
  }
}
