// -----------------------------------------------------------------------------
// Étends les styles des fenêtres modales
// -----------------------------------------------------------------------------

.modal-content > .btn-close {
  position: absolute;
  top: $modal-inner-padding;
  right: $modal-inner-padding;
  z-index: $zindex-modal + 1;
}
