// -----------------------------------------------------------------------------
// Mise en page de l'en-tête du site
// -----------------------------------------------------------------------------

$navbar-main-breakpoint: lg !default;
// Bootstrap 5 introduce changes to the behavior of  media-breakpoint-down
// Hence the "lg" value (https://getbootstrap.com/docs/5.0/migration/#sass)
$navbar-main-mobile-breakpoint: lg !default;

.header-logos svg {
  fill: $primary;
}

.social-network {
  margin-left: $spacer;
  font-size: 1.2em;
  &:hover {
    text-decoration: none;
  }
}

.header {
  &-text {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  &-resume {
    font-weight: 500;
    text-shadow: 1px 1px 2px $black;
  }
}

.page-header {
  position: relative;
  z-index: -1;
  margin-bottom: 2rem;
  text-align: center;
  border-bottom: 1px solid $tertiary;
  img {
    filter: brightness(66%);
  }
}
