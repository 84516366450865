// -----------------------------------------------------------------------------
// Styles du lecteur d'un fichier audio
// -----------------------------------------------------------------------------

.audio-container {
  padding: $spacer;
  color: $white;
  background-color: $primary;

  .plyr {
    &__controls {
      padding: 0;
    }
  }

  @include media-breakpoint-up(md) {
    padding: $spacer * 1.5 $spacer * 2;
  }
}

.audio-title {
  color: $white;
  text-align: center;
}

.no-js .audio-player {
  display: block;
}
