// -----------------------------------------------------------------------------
// Étends et personnalise la barre de navigation
// -----------------------------------------------------------------------------

.navbar-nav {
  .dropdown-menu {
    text-align: center;
  }
  .level-1 > .nav-link {
    font-size: 0.9em;
    text-transform: uppercase;
    &:focus {
      outline: 1px solid $primary;
    }
  }
  .level-2 > .nav-link {
    &:hover {
      color: $white;
      background: $primary;
      transition: background 1s, color 500ms;
    }
  }
  .nav-link {
    color: $primary;
    &.has-submenu {
      min-width: $dropdown-min-width;
    }
    &.active {
      color: $white;
    }
  }
  .nav-link-home {
    display: block;
    margin: var(--bs-nav-link-padding-y) 0;
    fill: $primary;
  }
}
