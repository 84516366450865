@use 'sass:string';

// variables
$animation-prefix: 'anim';
$animation-default-duration: 1s;
$animation-slide-distance: 250px;
$animation-slide-distance-x: $animation-slide-distance;
$animation-slide-distance-y: $animation-slide-distance;

@mixin animation-default {
  animation-duration: $animation-default-duration;
  animation-fill-mode: forwards;
}

// animation boilerplate. animation-name is generated by the parent class name
@mixin animation() {
  $name: str-slice(#{&}, 2, -1); // parent class without "."
  animation-name: $name;
  @include animation-default;
  @keyframes #{$name} {
    @content;
  }
}

// combine multiples animations by names
@mixin combined-animations($animation-names) {
  animation-name: $animation-names;
  @include animation-default;
}

@media (prefers-reduced-motion: no-preference) {
  .slide-right {
    @include animation {
      from {
        transform: translateX($animation-slide-distance-x);
      }
      to {
        transform: translateX(0);
      }
    }
  }
  .slide-left {
    @include animation {
      from {
        transform: translateX(-$animation-slide-distance-x);
      }
      to {
        transform: translateX(0);
      }
    }
  }
  .slide-up {
    @include animation {
      from {
        transform: translateY($animation-slide-distance-y);
      }
      to {
        transform: translateY(0);
      }
    }
  }
  .slide-down {
    @include animation {
      from {
        transform: translateY(-$animation-slide-distance-y);
      }
      to {
        transform: translateY(0);
      }
    }
  }
  .fade-in {
    @include animation {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  .scale-in {
    @include animation {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
  }
  .swap {
    @include animation {
      from {
        transform: scale(0, 0) translate(-700px, 0);
        transform-origin: 0 100%;
      }
      to {
        transform: scale(1, 1) translate(0, 0);
        transform-origin: 100% 100%;
      }
    }
  }
  .foolish-in {
    @include animation {
      0% {
        transform: scale(0, 0) rotate(360deg);
        transform-origin: 50% 50%;
      }
      20% {
        transform: scale(0.5, 0.5) rotate(0deg);
        transform-origin: 0% 100%;
      }
      40% {
        transform: scale(0.5, 0.5) rotate(0deg);
        transform-origin: 100% 100%;
      }
      60% {
        transform-origin: 0%;
      }
      80% {
        transform: scale(0.5, 0.5) rotate(0deg);
        transform-origin: 0% 0%;
      }
      100% {
        transform: scale(1, 1) rotate(0deg);
        transform-origin: 50% 50%;
      }
    }
  }
  .boing {
    @include animation {
      0% {
        transform: perspective(800px) rotateX(-90deg);
        transform-origin: 50% 0%;
      }
      50% {
        transform: perspective(800px) rotateX(50deg);
        transform-origin: 50% 0%;
      }
      100% {
        transform: perspective(800px) rotateX(0deg);
        transform-origin: 50% 0%;
      }
    }
  }
  .boing-soft {
    @include animation {
      0% {
        transform: perspective(800px) rotateX(-90deg);
        transform-origin: 50% 0%;
      }
      100% {
        transform: perspective(800px) rotateX(0deg);
        transform-origin: 50% 0%;
      }
    }
  }

  // combinaisons d'animations
  @each $name in (slide-left, slide-right, slide-down, slide-up) {
    .#{$name}-fade {
      @include combined-animations(($name, fade-in));
    }
  }
}

// control over the times
$animation-times: (
  1: 0.5s,
  2: 1s,
  3: 1.5s,
  4: 2s,
  5: 3s,
);

@each $i, $time in $animation-times {
  .#{$animation-prefix}-duration-#{$i} {
    animation-duration: $time;
  }
  .#{$animation-prefix}-delay-#{$i} {
    animation-delay: $time;
  }
}
