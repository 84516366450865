// -----------------------------------------------------------------------------
// Bootstrap's configuration for the application
// -----------------------------------------------------------------------------
// see: ../../node_modules/bootstrap/scss/_variables.scss

// Color system

$blue: #0779b8;
$red: #cb4424;
$green: #51a15a;
$yellow: #e0c86e;

$red-dark: darken($red, 20%);
$yellow-dark: darken($yellow, 20%); // plus foncé pour lisibilité
$green-dark: darken($green, 20%);
$blue-dark: #31555d;

$light: #f2f2f2;
$white: #fff;
$black: #000;

$primary: $red;
$secondary: $red;
$tertiary: $red;

$pale-blue: #dfdaff;
$pale-red: #ffded4;

// Add 'error' as an alternative to 'danger' since it is used by Django.
$theme-colors: (
  'error': $red,
  'primary': $primary,
  'secondary': $secondary,
  'tertiary': $tertiary,
  'red': $red,
);

$body-bg: $white;

// fonts
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-weights: $font-weight-light, $font-weight-normal, $font-weight-medium,
  $font-weight-bold;

$font-family-sans-serif: 'Raleway';

$headings-font-family: 'Patua One';
$headings-font-weight: 700;
$headings-color: $tertiary;

// cards
$card-border-color: $primary;

// Dropdown menu container
$dropdown-bg: $white;
$dropdown-border-radius: 0;
$dropdown-border-width: 1px;
$dropdown-spacer: 0;
$dropdown-link-color: $secondary;
$dropdown-link-hover-color: $tertiary;
$dropdown-link-hover-bg: none;
$dropdown-link-active-bg: darken($secondary, 5%);
$dropdown-link-active-color: $white;

// carousel
$carousel-indicator-width: 14px;
$carousel-indicator-height: 14px;
$carousel-indicator-hit-area-height: 8px;
$carousel-indicator-spacer: 4px;
$carousel-indicator-opacity: 0.4;
$carousel-indicator-active-bg: $secondary;
